import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import Seo from "../../components/seo"

const FoundationRepairFinancingPage = () => {
  return (
    <Layout>
      <Seo
        title={"Customer Financing for Foundation Repair Contractors"}
        description={
          "Create a revenue booster for your business with customer financing for foundation repair."
        }
      />
      <Helmet>
        <body className="foundation-repair-financing industry" />

        <script type="application/ld+json" async={true}>
          {`
 {"@context": "https://schema.org/",
 "@type": "Product",
 "name": "Customer Financing for Foundation Repair Contractors",
 "image": "",
 "description": "With customer financing for foundation repair, contractors can create a revenue booster for their business. When it comes to searching for and comparing contractors and construction companies, customers want flexible payment options — it's a must-have in the industry."
 }`}
        </script>
      </Helmet>

      <section
        className="banner-area editable-page-hero-banner"
        id="remodeling-page-banner-section"
        style={{
          backgroundImage:
            "url(" +
            "http://gbdevpress.enerbank.com/wp-content/uploads/2022/06/foundation-repair-hero1-scaled.jpg" +
            ")",
        }}
      >
        <div className="row align-items-center relative banner-area-text-container">
          <div className="container text-center">
            <h1 className="text-white" style={{ padding: "80 0" }}>
              Customer Financing for <br /> Foundation Repair Contractors
            </h1>
          </div>
        </div>
      </section>
      <section className="paragraph-text-block bg-light-gray">
        <div className="container">
          <div className="justify-content-center">
            <p>
              With customer financing for foundation repair, contractors can
              create a revenue booster for their business. When it comes to
              searching for and comparing contractors and construction
              companies, customers want flexible payment options — it's a
              must-have in the industry.
            </p>
            <p>
              Regions | EnerBank USA offers attractive financing options that
              your clients will love and appreciate. Our customer experience is
              excellent — we make it stress-free, with convenience at every step
              in the financing process.
            </p>
            <p className="font-weight-bold text-center short-divider">
              Fulfill your customers' needs with the help of EnerBank.&nbsp;
              <Link to="/sign-up/" className="text-blue">
                Sign-up now!
              </Link>
              &nbsp;
            </p>
          </div>
        </div>
      </section>
      <div className="section-header">
        <h2 className="text-center bg-blue">
          Why Your Foundation Repair Business Should Start Offering Financing
        </h2>
      </div>
      <section className="bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="mb-4">
                Foundation repairs can be expensive, depending on the job. An
                unsecured loan is an option that customers like to see when
                considering big purchases. Along with customer convenience, your
                business can also benefit.
              </p>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-image financing-card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/06/referrals-earnings.png"
                    alt="more referrals"
                  />
                </div>
                <div className="card-body benefit-card-body">
                  <h3 className="text-center">Get More Referrals</h3>
                  <p className="card-text">
                    Referrals are essential to your foundation repair business.
                    And how do you get referrals? By optimizing the customer
                    experience. One easy way to do so is by offering flexibility
                    and convenience. The last thing you want is to have golden
                    referrals turn away from your services because you don't
                    offer payment options.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-image financing-card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/06/get-ahead.png"
                    alt="Leave the Competition Behind"
                  />
                </div>
                <div className="card-body benefit-card-body">
                  <h3 className="text-center">Leave the Competition Behind</h3>
                  <p>
                    Depending on the geographic area your business is in,
                    competition can be high. When setting yourself apart with
                    irresistible offers, don't forget to include flexible
                    payment options. It may seem small, but it's undoubtedly a
                    high-impact strategy.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-image financing-card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/06/cancel1.png"
                    alt="Eliminate Discounting"
                  />
                </div>
                <div className="card-body benefit-card-body">
                  <h3 className="text-center">Eliminate Discounting</h3>
                  <p>
                    When you require full out-of-pocket payment for foundation
                    repair services, some clients may object to the price and
                    try to negotiate — pressuring you to consider offering a
                    discount to close the sale and keep revenue flowing. If a
                    tight budget limits your customers and they have the option
                    to finance, they'll be more likely to agree to your initial
                    bid. This lets you focus on value instead of price.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5">
              <p className="font-weight-bold text-center">
                Set yourself apart and optimize your business with flexibility
                and convenience.&nbsp;
                <Link to="/sign-up/">Get started with EnerBank today!</Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="section-header">
        <h2 className="text-center bg-blue">
          How to Start Offering Financing with EnerBank
        </h2>
      </div>
      <section className="bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-12 justify-content-center">
              <p className="mb-4">
                Your foundation repair business can quickly begin&nbsp;
                <Link to="/contractor-financing/" className="text-blue">
                  offering customer financing
                </Link>
                &nbsp; by using an EnerBank loan program! EnerBank is a leading
                expert in the industry, bringing over two decades of exclusive
                home improvement lending experience. That means our focus is on
                delivering the best financial products and services for home
                improvement professionals and homeowners. Our reliability and
                service will keep both you and your clients satisfied.
              </p>
              <h3 className="text-blue mb-4">
                Here's what makes EnerBank unique:
              </h3>
              <ul className="unique-points pl-2 ml-2">
                <li className="mb-3">
                  <span className="font-weight-bold">Convenience:</span>&nbsp;
                  <span>
                    We offer three quick and easy paperless application methods
                    for financing, so you can close the deal right in the
                    customer's home.
                  </span>
                </li>
                <li>
                  <span className="font-weight-bold">
                    Ultimate customer service:
                  </span>
                  &nbsp;
                  <span>
                    We provide a top-notch customer experience to help your
                    customers meet their goals.
                  </span>
                </li>
                <li>
                  <span className="font-weight-bold">Support:</span>
                  &nbsp;
                  <span>
                    We pair you with a dedicated relationship manager to help
                    with the addition of financing to your business.
                  </span>
                </li>
                <li>
                  <span className="font-weight-bold">Quick funding:</span>
                  &nbsp;
                  <span>
                    Funds are typically deposited into your account within 24
                    hours from when a homeowner approves a funding request.
                  </span>
                </li>
                <li>
                  <span className="font-weight-bold">High approval rates:</span>
                  &nbsp;
                  <span>
                    We're able to approve about 4 out of 5 applications for a
                    loan.
                  </span>
                </li>
              </ul>
              <div className="text-center short-divider">
                <p className="font-weight-bold">
                  Make your customers happy with a loan provider you can
                  trust.&nbsp;
                  <br /> To get started,&nbsp;
                  <Link to="/sign-up/" className="text-blue">
                    fill out this interest form
                  </Link>
                  &nbsp; to get more information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="why-enerbank bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-12 justify-content-center">
              <h2 className="text-center">
                Offer Easy Payment Options for Your Clients Today
              </h2>
              <p>
                Offering financing to your foundation repair clients can be a
                big difference maker for your business. Working with EnerBank
                brings an unparalleled level of stability and reliability to
                make you feel confident. Your customers will love the speed and
                convenience of being able to get approvals in seconds and our
                U.S.-based customer support. That&#39;s a lot of upsides to the
                decision!
              </p>
              <p className="font-weight-bold text-center short-divider short-divider--white">
                <Link
                  to="/contractor-financing/what-is-contractor-financing#start-offering-financing-for-clients"
                  className="btn btn-primary outline contractor-financing-button-white-outline"
                >
                  Start Offering Financing for Clients
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="enerbank-reason bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="mb-4 text-center">Why EnerBank?</h2>
              <p className="mb-4">
                EnerBank USA, now a part of Regions Bank, is a leader in the
                industry, with over two decades of exclusive home improvement
                lending experience. That means our focus is on delivering the
                best financial products and services for home improvement
                professionals and homeowners.
              </p>
              <p>
                Whether you're an industry organization looking to create a
                white-label loan program, a contractor looking to grow your
                business, or a homeowner starting a home improvement project,
                our paperless loan application, quick approvals, and easy
                payment disbursements make funding home improvements a breeze.
                We offer excellent customer service, the tools to help
                contractors succeed, and payment options custom-designed
                exclusively for homeowners who want to make their house a home.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="section-header">
        <h2 className="text-center bg-blue">
          Check Out the EnerBank Difference!
        </h2>
      </div>
      <section className="bg-light-gray">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center">
              <ul className="enerbank-difference">
                <li className="enerbank-difference-item">No hidden fees</li>
                <li className="enerbank-difference-item">
                  High approval rates
                </li>
                <li className="enerbank-difference-item">
                  Commitment to an excellent customer experience
                </li>
                <li className="enerbank-difference-item">
                  State-of-the-art technology for a smooth application process
                </li>
                <li className="enerbank-difference-item">
                  Custom marketing materials to support your business growth
                </li>
                <li className="enerbank-difference-item">
                  Dedicated relationship manager who understands your industry
                </li>
                <li className="enerbank-difference-item">
                  On-demand and live training in best practices to optimize your
                  experience
                </li>
              </ul>
            </div>
            <div className="col-12 short-divider">
              <p className="font-weight-bold text-center">
                <Link to="/sign-up/">Contact us today</Link>&nbsp;
                <span>
                  to learn more about growing your business through a payment
                  options program!
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default FoundationRepairFinancingPage
